import React, {useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import Feedback from "../Forms/Feedback";
import { useOktaAuth } from '@okta/okta-react';

const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;

const Step3 = () => {
    const navigate = useNavigate();

    const { oktaAuth } = useOktaAuth();

    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const  [
        deal,
        setDeal,
        dealId,
        setDealId,
        file,
        setFile,
        fileName,
        setFileName,
        discounts,
        setDiscounts,
        orderJson,
        setOrderJson,
    ] = useOutletContext();

    const handleBack = e => {
        navigate(-1);
    }

    const handleNext = _ => {
        upload()
    }

    const upload = () => {
        const reqBody = JSON.stringify({
            orderId: dealId,
            orderJson: orderJson
        });

        fetch(`${SERVER_API_URL}/api/upload`, {
            method: "POST",
            body: reqBody,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + oktaAuth.getAccessToken()
            },
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin" // include, *same-origin, omit
        })
            .then(response => {
                setSubmitted(true);
                if (response.status === 200) {
                    navigate("../success")
                } else {
                    response.json().then(json => {
                        if (json.body.validationResults) {
                            setError(json.body.validationResults.map(vr => {
                                return { isValid: vr.isValid, message: vr.message }
                            }))
                        } else if (json.body.message) {
                            setError([{
                                isValid: false,
                                message: json.body.message
                            }])
                        } else {
                            setError([{
                                isValid: false,
                                message: "Unknown Error"
                            }])
                        }
                    })
                }
            })
            .catch(err => {
                setSubmitted(true);
                console.error(err)
            });
    }

    const getError = () => {
        const getErrorRows = () => {
            return error.map(err => <li>{err.message}</li>)
        }

        if (error && error.length > 0) {
            return <>
                <h2 className="error">Errors</h2>
                <ul>
                    {getErrorRows()}
                </ul>
            </>
        }
    }

    return (
        <>
            <div>
                <h2>Step 3</h2>
                <span>Please review the following items associated with your quote. Assuming there are no issues,
                    it will be imported and associated with Deal ID {dealId}. (No data has been imported at this point.)
                    Press Import to complete this process or press Back to make corrections.</span>
            </div>
            <Feedback />
            <div className="action-buttons-container">
                <button onClick={handleBack}>Back</button>
                <button onClick={handleNext}>Import</button>
            </div>
            {getError()}
            {/*{getSuccessWidget()}*/}
        </>
    );
}

export default Step3