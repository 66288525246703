import React, {useEffect, useRef, useState} from 'react';
import {useOktaAuth} from "@okta/okta-react";
import * as CryptoJS from 'crypto-js'

const Nav = () => {
    const {authState, oktaAuth} = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const e7HeaderLogo= process.env.PUBLIC_URL + '/e7-horizontal-white-logo.svg'

    const handleLogin = () => oktaAuth.signInWithRedirect();

    const handleLogout = () => oktaAuth.signOut();

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    // Close dropdown when clicking outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [dropdownRef]);

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            setUserInfo(authState.idToken.claims);
            // You can also get user information from the `/userinfo` endpoint
            /*oktaAuth.getUser().then((info) => {
              setUserInfo(info);
            });*/
        }
    }, [authState, oktaAuth]); // Update if authState changes

    const loggedOut = () => {
        return (
            <div className="user-info">
            </div>
        );
    }

    const getGravatarLink = () => {
        const hash = CryptoJS.MD5(userInfo.email).toString();
        return "https://www.gravatar.com/avatar/" + hash
    }

    const loggedIn = () => {
        return (
            <div className="user-section" ref={dropdownRef}>
                <img src={getGravatarLink()}  alt="avatar" className="gravatar"/>
                <button onClick={toggleDropdown} className="user-button">
                    <span className="header-footer-text">{userInfo.name} ▼</span>
                </button>
                {isDropdownOpen && (
                    <div className="dropdown-menu">
                        <div className="dropdown-item">{userInfo.email}</div>
                        <button onClick={handleLogout}>Log Out</button>
                    </div>
                )}
            </div>
        );
    }

    return (
        <header>
            <div className="header-container">

                <div className="logo-container">
                    <img src={e7HeaderLogo} alt="E7 Solutions" height="10" />
                    <span className="largish header-footer-text"> | AQuA</span>
                </div>

                <div className="user-menu">
                {userInfo ? loggedIn() : loggedOut()}
                </div>

            </div>
        </header>
    );
};

export default Nav;