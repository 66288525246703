import React from 'react';

function ReviewTable({orderJson}) {

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    let DTFormat = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    const formatMoney = price => {
        return price ? USDollar.format(price) : "$0";
    }

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return DTFormat.format(date);
        }

        return ''
    }

    const isMultiHost = () => {
        const distinctHostnames = getDistinctHostNames();
        return distinctHostnames.length > 1;
    }

    const getTotalByHost = (hostname) => {
        return orderJson['lineItems']
            .filter(lineItem => lineItem.host_name.toUpperCase() === hostname.toUpperCase())
            .map(lineItem => lineItem.price)
            .reduce((total, price) => total + price)
    }

    const buildTotalRowFromProduct = (hostname) => {
        // if (!isMultiHost()) {
            return <tr>
                <td className="left">Total</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td className="right">{formatMoney(getTotalByHost(hostname))}</td>
            </tr>
        // }

        // return <></>
    }

    const buildDiscountRowFromProduct = () => {
        if (!isMultiHost()) {
            return <tr>
                <td className="left">Discount</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td className="right">{formatMoney(orderJson['discount'])}</td>
            </tr>
        }

        return <></>
    }

    const buildRowFromProduct = (lineItemJson) => {
        return <tr>
            <td className="left">{lineItemJson['name']}</td>
            <td>{lineItemJson['host_type']}</td>
            <td>{lineItemJson['product_type']}</td>
            <td>{lineItemJson['seats']}</td>
            <td>{lineItemJson['sen']}</td>
            <td>{lineItemJson['new_upgrade_renewal']}</td>
            <td>{formatDate(lineItemJson['renewal_begin_period'])}</td>
            <td>{formatDate(lineItemJson['renewal_end_period'])}</td>
            <td className="right">{formatMoney(lineItemJson['discount'])}</td>
            <td className="right">{formatMoney(lineItemJson['net_price'])}</td>
        </tr>
    }

    const buildProductRows = (hostName) => {
        const productsJson = orderJson['lineItems']
            .filter(lineItems => lineItems.host_name.toUpperCase() === hostName.toUpperCase());
        return productsJson.map(productJson => {
            return buildRowFromProduct(productJson);
        });
    }

    const getDistinctHostNames = () => {
        console.log('pie', orderJson)
        const allHostNames = orderJson['lineItems'].map(li => li['host_name']);
        return [...new Set(allHostNames)]
    }

    const buildTables = () => {
        const hostNames = getDistinctHostNames()
        return hostNames.map(hostName => {
            return buildTable(hostName)
        })
    }

    const buildTable = (hostName) => {
        const getHostLabel = () => {
            if (getDistinctHostNames().length > 1 || hostName !== "") {
                return <div className="pad-10">
                    <span className="bold-note">{hostName ? hostName : "No Host"}</span>
                </div>
            }
        }

        return (
            <>
                {getHostLabel()}
                <div className="table-responsive">
                    <table>
                        <thead>
                        <tr>
                            <th className="left" width="300px;">Product</th>
                            <th className="center" width="100px;">Host Type</th>
                            <th className="center" width="100px;">Product Type</th>
                            <th className="center">Seats</th>
                            <th className="center">SEN</th>
                            <th className="center" width="100px;">Order Type</th>
                            <th className="center">Start Date</th>
                            <th className="center">End Date</th>
                            <th className="center">Discount</th>
                            <th className="center">Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {buildProductRows(hostName)}
                        {/*{buildDiscountRowFromProduct(hostName)}*/}
                        {buildTotalRowFromProduct(hostName)}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    return (
        <div className="gray-container">

            {buildTables()}
        </div>
    );

}

export default ReviewTable;
