import React from "react";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import ReviewTable from "../Forms/ReviewTable";

const Step2 = () => {
    const navigate = useNavigate();

    const  [
        deal,
        setDeal,
        dealId,
        setDealId,
        file,
        setFile,
        fileName,
        setFileName,
        discounts,
        setDiscounts,
        orderJson,
        setOrderJson,
    ] = useOutletContext();

    const handleBack = e => {
        navigate(-1);
    }

    const handleNext = e => {
        navigate("../step3")
    }

    return (
        <>
            <div>
                <h2>Step 2</h2>
                <span>Please confirm the following looks correct. It will be imported and associated with Deal ID {deal.id}. (No data has been imported at this point.) Press Next if there are no issues or Back to make corrections.</span>
                <div className="pad-10"/>
            </div>
            <ReviewTable orderJson={orderJson}/>
            <div className="action-buttons-container">
                <button onClick={handleBack}>Back</button>
                <button onClick={handleNext}>Next</button>
            </div>
        </>
    );
}

export default Step2