const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID
const OKTA_REDIRECT_URL = process.env.REACT_APP_OKTA_REDIRECT_URL

export const oktaConfig = {
    clientId: `${OKTA_CLIENT_ID}`,
    issuer: `https://e7solutions.okta.com`,
    redirectUri: `${OKTA_REDIRECT_URL}`, // this makes it so redirects to login if not logged in for secure routes
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: true,
}