import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";

import { useOktaAuth } from '@okta/okta-react';
import APIGetForm from "../Forms/APIGetForm"; // Grid version 2

const Step1_API = () => {
    const { oktaAuth } = useOktaAuth();

    const navigate = useNavigate();

    const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;

    const [
        deal,
        setDeal,
        dealId,
        setDealId,
        file,
        setFile,
        fileName,
        setFileName,
        discounts,
        setDiscounts,
        orderJson,
        setOrderJson,
    ] = useOutletContext();

    const [verified, setVerified] = useState(deal != null && deal.hasOwnProperty('properties'));
    const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
    const [accountTxId, setAccountTxId] = useState('');
    const [isLoadAttempted, setIsLoadAttempted] = useState(false)

    useEffect(() => {
        console.log("orderJson", orderJson)
        setIsReadyToSubmit(verified && deal && orderJson && isLoadAttempted);
    }, [verified, deal, orderJson, isLoadAttempted]);

/*
    useEffect(() => {
        setVerified(false);
        setDeal(null);
    }, [accountTxId]);
*/

    const handleSubmit = _ => {
        if (isReadyToSubmit) {
            navigate("../step2")
        }
    }

    const handleVerify = _ => {
        if (dealId && dealId > 0) {
            fetch(`${SERVER_API_URL}/api/verifyHSOrderId/${dealId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + oktaAuth.getAccessToken()
                },
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-store", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin" // include, *same-origin, omit
            })
                .then(response => {
                    if (response.status === 200) {
                        response.json().then(json => {
                            setDeal(json)
                            setVerified(true)
                        })
                    } else {
                        setVerified(true)
                        setDeal(null);
                    }
                })
                .catch(err => {
                    setVerified(true);
                    setDeal(null)
                    console.error(err)
                });
        }
    }

    const handleLoad = _ => {
        console.log(accountTxId);
        if (accountTxId) {
            console.log(SERVER_API_URL)
            fetch(`${SERVER_API_URL}/api/fetchAtlQuote/${accountTxId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + oktaAuth.getAccessToken()
                },
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-store", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin" // include, *same-origin, omit
            })
                .then(response => {
                    setIsLoadAttempted(true)
                    if (response.status === 200) {
                        response.json().then(json => {
                            console.log("A", json)
                            setOrderJson(json)
                        })
                    } else {
                        console.log("B")
                        setOrderJson(null)
                    }
                })
                .catch(err => {
                    setIsLoadAttempted(true)
                    // setVerified(true);
                    // setDeal(null)
                    console.error("C", err)
                    setOrderJson(null)
                });
        }
    }


    return (
        <>
            <div>
                <h2>Step 1</h2>
                <span>Begin by pulling your quote from the Atlassian Commerce API</span>
            </div>
            <APIGetForm dealId={dealId} setDealId={setDealId} deal={deal} setDeal={setDeal} accountTxId={accountTxId} setAccountTxId={setAccountTxId} file={file} setFile={setFile}
                       fileName={fileName} setFileName={setFileName} orderJson={orderJson} setOrderJson={setOrderJson} verified={verified} handleVerified={handleVerify}
                       handleLoad={handleLoad} isLoadAttempted={isLoadAttempted} setIsLoadAttempted={setIsLoadAttempted} setVerified={setVerified}/>
            <div className="action-buttons-container">
                <button disabled={!isReadyToSubmit} onClick={handleSubmit}>Confirm</button>
            </div>
        </>
    );
}

export default Step1_API