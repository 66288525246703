import {Link, useNavigate, useOutletContext} from "react-router-dom";
import React from "react";

const Home = () => {

    const navigate = useNavigate()

    const  [
        deal,
        setDeal,
        dealId,
        setDealId,
        file,
        setFile,
        fileName,
        setFileName,
        discounts,
        setDiscounts,
        orderJson,
        setOrderJson,
    ] = useOutletContext();

    const startOver = () => {
        setOrderJson(null)
        setFile(null)
        setFileName(null)
        setDiscounts(null)
        setDealId(null)
        setDeal(null);
        navigate('/protected')
    }

    return (
        <div className="App">
            <h2>Success!</h2>

            <div className="action-buttons-container">
                <button onClick={startOver}>Start Over</button>
            </div>
        </div>
    );
}

export default Home