import {Link, useNavigate} from "react-router-dom";
import React from "react";

const Home = ({authState}) => {

    const navigate = useNavigate()

    const begin = () => {
        navigate('/protected')
    }

    return (
        <div className="App">
            <span>Welcome to the Atlassian Quote Accelerator, or AQuA for short. AQuA is an important utility that was built to enhance the velocity of information for E7 software orders.</span>
            <br/>

            <div className="action-buttons-container">
                <button onClick={begin}>Begin</button>
                {/*<Link id='protected-nav-link' to='/protected'>Go</Link>*/}
            </div>
        </div>
    );
}

export default Home