
import {useEffect, useState} from "react";
import {Outlet, useOutletContext} from "react-router-dom";

const DealUploadWizard = () => {

    const [deal, setDeal] = useState('');
    const [dealId, setDealId] = useState('');
    const [discounts, setDiscounts] = useState('');
    const [fileName, setFileName] = useState(''); // State to store the file name
    const [file, setFile] = useState(null);
    const [orderJson, setOrderJson] = useState("");

    return (
            <Outlet context={ [
                deal,
                setDeal,
                dealId,
                setDealId,
                file,
                setFile,
                fileName,
                setFileName,
                discounts,
                setDiscounts,
                orderJson,
                setOrderJson,
            ] }/>
    );
}

export default DealUploadWizard;