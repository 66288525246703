import './App.css';

import {useNavigate} from 'react-router-dom';

import {Security} from "@okta/okta-react";
import {OktaAuth, toRelativeUrl} from "@okta/okta-auth-js";
import {oktaConfig} from "./oktaConfig";
import React from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Routes from "./Routes";

// noinspection JSCheckFunctionSignatures
const oktaAuth = new OktaAuth(oktaConfig);

const App = () => {
    const navigateTo = useNavigate();

    console.log(oktaAuth)

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        navigateTo(toRelativeUrl(originalUri || "/", window.location.origin));
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Header/>
            <main className="main-content">
                <Routes/>
            </main>
            <Footer/>
        </Security>
    );
}

export default App;