import React from 'react';
import evalOrder from "../../guidance/guidance";

function Feedback() {

    const getFeedbackList = () => {
        if (false) {
            return <ul>
                <li>Cake</li>
                <li>Pie</li>
            </ul>
        } else {
            return <span>No Issues!</span>
        }
    }

    return (
        <div className="gray-container center">
            <div>
                <span className="bold-note">Feedback</span>
            </div>
            {getFeedbackList()}
        </div>
    );

}

export default Feedback;
